import { ExampleResponse } from "components/ExampleResponse";
import { AttributeTable } from "components/AttributeTable";
import { CodeExample } from "components/CodeExample";
import { EndpointsTable } from "components/EndpointsTable";
import * as React from 'react';
export default {
  ExampleResponse,
  AttributeTable,
  CodeExample,
  EndpointsTable,
  React
};