import { EndpointsTable } from "components/EndpointsTable";
import { AttributeTable } from "components/AttributeTable";
import { Endpoint } from "components/Endpoint";
import { CodeExample } from "components/CodeExample";
import * as React from 'react';
export default {
  EndpointsTable,
  AttributeTable,
  Endpoint,
  CodeExample,
  React
};